import React from 'react';
import './SkillSet.css';
import Navbar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import SkillSetExp from '../../components/skillsetsexp/SkillSetsExp';
import Fade from 'react-reveal/Fade';

const SkillSet = () => {
	return (
		<Fade duration={1000}>
		<div>
			<Navbar />
			<SkillSetExp />
			<Footer />
		</div>
		</Fade>
	);
};

export default SkillSet;
