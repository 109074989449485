import React from 'react';
import './SkillSetsExp.css';

const SkillSetsExp = () => {
	return (
		<div className='skills-container'>
			<h1 className='title'>Technical Experience</h1>
			<h2 className='table-title'>Systems Administration</h2>
			<table>
				<tbody>
					<tr>
						<td>Windows Server</td>
						<td>Kaseya</td>
						<td>Kaspersky</td>
					</tr>
					<tr>
						<td>Ghost</td>
						<td>WAIK</td>
						<td>Acronis</td>
					</tr>
					<tr>
						<td>Active Boot</td>
						<td>Windows Deployment Server</td>
						<td>USMT</td>
					</tr>
					<tr>
						<td>WSUS</td>
						<td>Print Server</td>
						<td>Azure</td>
					</tr>
					<tr>
						<td>Powershell Scripting</td>
						<td>Batch File Scripting</td>
						<td>Kaseya Data Backup</td>
					</tr>
					<tr>
						<td>Veeam</td>
						<td>Active Directory</td>
						<td>Group Policy</td>
					</tr>
					<tr>
						<td>DHCP</td>
					</tr>
				</tbody>
			</table>
			<h2 className='table-title'>Virtualization</h2>
			<table>
				<tbody>
					<tr>
						<td>VMware ESXi</td>
						<td>Hyper-V</td>
						<td>Citrix Director</td>
					</tr>
				</tbody>
			</table>
			<h2 className='table-title'>VOIP</h2>
			<table>
				<tbody>
					<tr>
						<td>Cisco IP Phone Configuration</td>
						<td>Voicemail Management</td>
						<td>Cisco Unity Connection</td>
					</tr>
					<tr>
						<td>Jabber</td>
					</tr>
				</tbody>
			</table>
			<h2 className='table-title'>Networking</h2>
			<table>
				<tbody>
					<tr>
						<td>LAN</td>
						<td>WAN</td>
					</tr>
					<tr>
						<td>WiFi</td>
						<td>Wireless Access Points</td>
					</tr>
					<tr>
						<td>VPN & MFA</td>
						<td>Cisco Any Connect</td>
					</tr>
					<tr>
						<td>RSA SecureID</td>
						<td>OKTA</td>
					</tr>
				</tbody>
			</table>
			<h2 className='table-title'>Web Development</h2>
			<table>
				<tbody>
					<tr>
						<td>JavaScript</td>
						<td>React</td>
						<td>MySQL</td>
					</tr>
					<tr>
						<td>MongoDB</td>
						<td>HTML5</td>
						<td>CSS</td>
					</tr>
					<tr>
						<td>jQuery</td>
						<td>API</td>
						<td>Node JS</td>
					</tr>
					<tr>
						<td>Express</td>
						<td>MERN</td>
						<td>MVC</td>
					</tr>
					<tr>
						<td>Web Scraping</td>
					</tr>
				</tbody>
			</table>

			<a
				href='https://drive.google.com/file/d/1wHoDWo03m41zQ2XBws1aauyAOqsXlRMx/view?usp=share_link;'
				className='resume-link'
				target='_blank'
				rel='noopener noreferrer'
				title='Paul DelPadre Resume'
				>
				View my resume
			</a>
		</div>
	);
};

export default SkillSetsExp;
