import React, { Component } from 'react';

class GoogleMap extends Component {
	constructor(props) {
		super(props);
		this.googleMapRef = React.createRef();
		this.googleMap = null;
		this.marker = null;
	}

	componentDidMount() {
		const api = 'AIzaSyB50VgFHr2MEFWKOBnq9g284Tbhj-LsD_U';
		const googleMapScript = document.createElement('script');
		googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${api}`;
		window.document.body.appendChild(googleMapScript);

		googleMapScript.addEventListener('load', () => {
			this.googleMap = this.createGoogleMap();
			this.marker = this.createMarker();
		});
	}

	createGoogleMap = () =>
		new window.google.maps.Map(this.googleMapRef.current, {
			zoom: 12,
			center: {
				lat: 28.670537,
				lng: -81.208437,
			},
			disableDefaultUI: true,
		});

	createMarker = () =>
		new window.google.maps.Marker({
			position: { lat: 28.670537, lng: -81.208437 },
			map: this.googleMap,
		});

	render() {
		return (
			<div
				id='google-map'
				ref={this.googleMapRef}
				style={{ width: '65%', height: '500px' }}
			/>
		);
	}
}

export default GoogleMap;
