import './App.css';
// import Navbar from './components/navbar/Navbar';
import AppRouter from './AppRouter';
import './index.js';

function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
