// import React from 'react';
// import { Link } from 'react-router-dom';
// import './NavBar.css';
// // import logo from './logo.png';
// import Slide from 'react-reveal/Slide';

// const Navbar = () => {
//   return (
//     <nav className="navbar sticky">
//       <div className="navbar-logo">
//         {/* <img src={logo} alt="Logo" /> */}
//       </div>
//       <Slide left duration={500}>
//         <ul className="nav-links">
//           <li>
//             <Link to="/" className="nav-link">Home</Link>
//           </li>
//           <li>
//             <Link to="/contact" className="nav-link">Contact</Link>
//           </li>
//           <li>
//             <Link to="/portfolio" className="nav-link">Portfolio</Link>
//           </li>
//           <li>
//             <Link to="/skillset" className="nav-link">Skill Sets</Link>
//           </li>
//           <li>
//             <Link to="/about" className="nav-link">About</Link>
//           </li>
//         </ul>
//       </Slide>
//     </nav>
//   );
// };

// export default Navbar;

// // Original Nav

import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import Slide from 'react-reveal/Slide';
import WeatherApp from '../coding/app/weatherApp/WeatherApp';

const Navbar = () => {
	return (
		<nav className='navbar'>
			<Slide left duration={500}>
				<ul className='nav-links'>
					<li>
						<Link to='/' className='nav-link' title='Home Page'>
							Home
						</Link>
					</li>
					<li>
						<Link to='/contact' className='nav-link' title='Contact Paul DelPadre Page'>
							Contact
						</Link>
					</li>
					<li>
						<Link to='/portfolio' className='nav-link' title='Portfolio and Powershell Scripts Page'>
							Portfolio
						</Link>
					</li>
					<li>
						<Link to='/skillset' className='nav-link' title='IT Technical Skills Page'>
							Skill Sets
						</Link>
					</li>
					<li>
						<Link to='/about' className='nav-link' title='About Me Page'>
							About
						</Link>
					</li>
				</ul>
			</Slide>
			<WeatherApp />
		</nav>
	);
};

export default Navbar;
