import React from 'react';
import './PowerShell.css';
import PowerShellCards from './cards/PowerShellCards';
import PowerShellCardData from './cards/PowerShellCardData';

function PowerShell() {
    return (
      <div className="card-container">
        {PowerShellCardData.map((card, index) => (
          <PowerShellCards
            key={index}
            image={card.image}
            title={card.title}
            text={card.text}
            link={card.link}
            buttonText={card.buttonText}
          />
        ))}
      </div>
    );
  }
  
  export default PowerShell;