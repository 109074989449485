import React from 'react';
import Navbar from '../../components/navbar/NavBar';
import Hero from '../../components/hero/HeroMain';
import Footer from '../../components/footer/Footer';
import SkillSetsExp from '../../components/skillsetsexp/SkillSetsExp';
import Fade from 'react-reveal/Fade';

const Home = () => {
  return (
    <div>
      <Navbar />
      <Fade duration={1000}>
      <Hero />
      <SkillSetsExp />
      <Footer />
      </Fade>
    </div>
    
  );
};

export default Home;