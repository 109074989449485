import React, { useState } from 'react';
import './PasswordGen.css';

const PasswordGen = () => {
  const [password, setPassword] = useState('');
  const [length, setLength] = useState(12);
  const [includeLowercase, setIncludeLowercase] = useState(true);
  const [includeUppercase, setIncludeUppercase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeSymbols, setIncludeSymbols] = useState(true);

  const generatePassword = () => {
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numberChars = '0123456789';
    const symbolChars = '!@#$%^&*()_-+={}[];:<>,.?/~`';

    let chars = '';
    if (includeLowercase) chars += lowercaseChars;
    if (includeUppercase) chars += uppercaseChars;
    if (includeNumbers) chars += numberChars;
    if (includeSymbols) chars += symbolChars;

    let newPassword = '';
    for (let i = 0; i < length; i++) {
      newPassword += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    setPassword(newPassword);
  };

  const handleLengthChange = (event) => {
    setLength(parseInt(event.target.value));
  };

  const handleIncludeLowercaseChange = () => {
    setIncludeLowercase(!includeLowercase);
  };

  const handleIncludeUppercaseChange = () => {
    setIncludeUppercase(!includeUppercase);
  };

  const handleIncludeNumbersChange = () => {
    setIncludeNumbers(!includeNumbers);
  };

  const handleIncludeSymbolsChange = () => {
    setIncludeSymbols(!includeSymbols);
  };

  return (
    <div className="password-generator">
      <h2>Password Generator</h2>
      <div className="password-options">
        <div className="option">
          <label htmlFor="length-input">Length:</label>
          <input type="number" id="length-input" value={length} onChange={handleLengthChange} />
        </div>
        <div className="option">
          <label htmlFor="lowercase-checkbox">Lowercase:</label>
          <input
            type="checkbox"
            id="lowercase-checkbox"
            checked={includeLowercase}
            onChange={handleIncludeLowercaseChange}
          />
        </div>
        <div className="option">
          <label htmlFor="uppercase-checkbox">Uppercase:</label>
          <input
            type="checkbox"
            id="uppercase-checkbox"
            checked={includeUppercase}
            onChange={handleIncludeUppercaseChange}
          />
        </div>
        <div className="option">
          <label htmlFor="numbers-checkbox">Numbers:</label>
          <input
            type="checkbox"
            id="numbers-checkbox"
            checked={includeNumbers}
            onChange={handleIncludeNumbersChange}
          />
        </div>
        <div className="option">
          <label htmlFor="symbols-checkbox">Symbols:</label>
          <input
            type="checkbox"
            id="symbols-checkbox"
            checked={includeSymbols}
            onChange={handleIncludeSymbolsChange}
          />
        </div>
      </div>
      <button className="generate-button" onClick={generatePassword}>
        Generate Password
      </button>
      {password && <div className="generated-password">{password}</div>}
    </div>
  );
};

export default PasswordGen;
