import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './HeroMain.css';
import heroImg from '../../images/image1.jpg';

const Hero = () => {
  const [buttonText, setButtonText] = useState('Want to See My Projects?');

  const handleMouseEnter = () => {
    setButtonText('Click Here');
  };

  const handleMouseLeave = () => {
    setButtonText('Want to See My Projects?');
  };

  return (
    <div className="hero-container">
      <div className="image-container">
        <img src={heroImg} alt="Hero"
        title='Paul DelPadre IT Specialist' />
      </div>
      <div className="text-container">
        <h1>Paul DelPadre</h1>
        <p>
          Welcome.
My name is Paul DelPadre, l'm an IT Specialist based in Oviedo, FL. 
<br />
<br />
I have experience supporting Active Directory Environments, Technical Support, .
<br />
<br />
I'm passionate about creating scripts to automate receptive tasks to minimize IT tasks overhead.

        </p>
        <Link to="/portfolio" className="nav-link">
          <button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {buttonText}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Hero;






// original for hero
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import './HeroMain.css';

// const Hero = () => {
//   const [buttonText, setButtonText] = useState('Want to See My Projects?');

//   const handleMouseEnter = () => {
//     setButtonText('Click Here');
//   };

//   const handleMouseLeave = () => {
//     setButtonText('Want to See My Projects?');
//   };

//   return (
//     <div className="hero-container">
//       <h1>Paul DelPadre</h1>
//       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor, velit in fringilla ultricies, velit sapien tempus arcu, non porttitor est ipsum in neque.</p>
//       <Link to="/portfolio" className="nav-link">
//         <button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//           {buttonText}
//         </button>
//       </Link>
//     </div>
//   );
// };

// export default Hero;
