import React from 'react';
import './About.css';
import Navbar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import Fade from 'react-reveal/Fade';

const About = () => {
	return (
		<div>
			<Navbar />
			<Fade duration={1000}>
			<div className='about'>
				<h1 className='about-title'>About Me</h1>
				<p className='about-text'>
					<br />
					<br />
					As a recent graduate of UCF's intensive 3-month boot camp for full
					stack developers, I am thrilled to embark on my career as a web
					developer. With a strong foundation in web development technologies
					such as JavaScript, React, MySQL, MongoDB, HTML5, CSS, jQuery, API,
					Node JS, Express, MERN, and MVC, I am confident in my abilities to
					contribute to any project.
					<br />
					<br />
					Throughout my time in the boot camp, I honed my skills in full stack
					web development, including front-end and back-end development,
					database management, and server-side scripting. I am excited to put
					these skills into practice and continue learning and growing in a
					professional setting.
					<br />
					<br />
					I am a collaborator who works well on team-based projects as well as
					independently. I have proven complex problem-solving abilities and am
					recognized for contributing effectively as a part of a fast-paced,
					quality-driven team.
					<br />
					<br />
					I have extensive experience in project coordination, system upgrades and integration
					has given me first-hand experience with Systems Administration tools Throughout my years as a Systems Administrator and IT Support Engineer.
					<br />
					<br />
					Additionally, I have experience in Data Backup Administration with
					Kaseya Data Backup and Veeam
					<br />
					<br />I am an eager learner who is ready to jump in and take on
					challenges in order to assist with the efficiency of the department
					and company. My excellent interpersonal communication skills make me a
					valuable addition to any team.
				</p>
				<Footer />
			</div>
			</Fade>
		</div>
	);
};

export default About;
