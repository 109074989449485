import React from 'react';
import './FormContact.css';
import GoogleMap from '../googleMap/GoogleMap';

const FormContact = () => {
  const email = 'IT@delpadre.xyz';
  const subject = 'Contact Us';
  const body = 'I am inquiring about your service';

  const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return (
    <div className="contact">
      <h1 className="contact-title">Feel Free to Reach Out!</h1>
      <p className="contact-text">Please feel free to reach out to me via email.</p>
      <a href={mailtoUrl} target="_blank" rel="noopener noreferrer" className="contact-link">Contact Me</a>
    <GoogleMap />
    </div>
  );
};

export default FormContact;