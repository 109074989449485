import React, { Component } from 'react';
import './Coding.css';
import PasswordGen from './app/passwordGen/PasswordGen';
import WeatherApp from './app/weatherApp/WeatherApp';
import Counter from './app/counter/Counter';
import GroceryList from './app/grocery/GroceryList';

const Coding = () => {
	return (
		<div>
			<h1>Coding</h1>
			<PasswordGen />
			<h2>Weather and Date</h2>
			<WeatherApp />
			<Counter />
			<GroceryList />
		</div>
	);
};

export default Coding;
