import React from 'react';
import './ScriptsStyles.css';
import Fade from 'react-reveal/Fade';

function PowerShellCards(props) {
  return (
      <Fade top distance='20%' duration={500}>
    <div className="card">
      <img title ='Paul DelPadre Powershell Script Image for IT Support Automation'src={props.image} alt={props.title} />
      <div className="card-body">
        <h3 className="card-title">{props.title}</h3>
        <p className="card-text">{props.text}</p>
        <a title ='Paul DelPadre Powershell Scripts for IT Support Automation' href={props.link} className="btn" download target="#">{props.buttonText}</a>
      </div>
    </div>
      </Fade>
  );
}

export default PowerShellCards;
