import React, { useState, useEffect } from 'react';
import './WeatherApp.css';

const WeatherApp = () => {
	const [location, setLocation] = useState({ city: '', state: '' });
	const [weather, setWeather] = useState({});

	useEffect(() => {
		// Get user's location using browser's geolocation API
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const { latitude, longitude } = position.coords;

				// Get city and state using Google Maps API
				fetch(
					`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GAPI}`
				)
					.then((response) => response.json())
					.then((data) => {
						const city = data.results[0].address_components.find(
							(component) =>
								component.types.includes('locality') ||
								component.types.includes('administrative_area_level_2')
						).long_name;
						const state = data.results[0].address_components.find((component) =>
							component.types.includes('administrative_area_level_1')
						).short_name;

						setLocation({ city, state });
					})
					.catch((error) => console.log(error));

				// Get weather information using OpenWeatherMap API
				fetch(
					`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${process.env.REACT_APP_WAPI}&units=imperial`
				)
					.then((response) => response.json())
					.then((data) => setWeather(data))
					.catch((error) => console.log(error));
			},
			(error) => console.log(error)
		);
	}, []);

	return (
		<div className='weather-app'>
			{/* <h3>Local Weather</h3> */}
			<p className='location'>
				{`${location.city}, ${location.state}`} <br />
				{`${weather.main?.temp}°F ${weather.weather?.[0]?.description}`}
				<br />
				{weather.dt &&
					new Date(weather.dt * 1000).toLocaleDateString('en-US', {
						month: 'long',
						day: 'numeric',
						year: 'numeric',
					})}
			</p>
		</div>
	);
};

export default WeatherApp;

// import React, { useState, useEffect } from "react";

// const WeatherApp = () => {
//   const [location, setLocation] = useState({ city: "", state: "" });
//   const [weather, setWeather] = useState({});

//   useEffect(() => {
//     // Get user's location using browser's geolocation API
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;

//         // Get city and state using Google Maps API
//         fetch(
//           `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyB50VgFHr2MEFWKOBnq9g284Tbhj-LsD_U`
//         )
//           .then((response) => response.json())
//           .then((data) => {
//             const city = data.results[0].address_components.find(
//               (component) =>
//                 component.types.includes("locality") ||
//                 component.types.includes("administrative_area_level_2")
//             ).long_name;
//             const state = data.results[0].address_components.find(
//               (component) =>
//                 component.types.includes("administrative_area_level_1")
//             ).short_name;

//             setLocation({ city, state });
//           })
//           .catch((error) => console.log(error));

//         // Get weather information using OpenWeatherMap API
//         fetch(
//           `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=22178e558cdab69ac05e6565e5c4de1c&units=imperial`
//         )
//           .then((response) => response.json())
//           .then((data) => setWeather(data))
//           .catch((error) => console.log(error));
//       },
//       (error) => console.log(error)
//     );
//   }, []);

//   return (
//     <div>
//       <h3>Local Weather</h3>
//       <p>{`${location.city}, ${location.state}`}</p>
//       <p>{`${weather.main?.temp}°F ${weather.weather?.[0]?.description}`}</p>
//     </div>
//   );
// };

// export default WeatherApp;

// import React, { useState, useEffect } from "react";

// const WeatherApp = () => {
//   const [location, setLocation] = useState({ city: "", state: "" });
//   const [weather, setWeather] = useState({});

//   useEffect(() => {
//     // Get user's location using browser's geolocation API
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;

//         // Get city and state using Google Maps API
//         fetch(
//           `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=API_KEY`
//         )
//           .then((response) => response.json())
//           .then((data) => {
//             const city = data.results[0].address_components.find(
//               (component) =>
//                 component.types.includes("locality") ||
//                 component.types.includes("administrative_area_level_2")
//             ).long_name;
//             const state = data.results[0].address_components.find(
//               (component) =>
//                 component.types.includes("administrative_area_level_1")
//             ).short_name;

//             setLocation({ city, state });
//           })
//           .catch((error) => console.log(error));

//         // Get weather information using OpenWeatherMap API
//         fetch(
//           `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=API_KEY&units=metric`
//         )
//           .then((response) => response.json())
//           .then((data) => setWeather(data))
//           .catch((error) => console.log(error));
//       },
//       (error) => console.log(error)
//     );
//   }, []);

//   return (
//     <div>
//       <h1>Weather App</h1>
//       <p>{`${location.city}, ${location.state}`}</p>
//       <p>{`${weather.main?.temp}°C ${weather.weather?.[0]?.description}`}</p>
//     </div>
//   );
// };

// export default WeatherApp;

// import React, { useState, useEffect } from 'react';

// const WeatherApp = () => {
//   const [location, setLocation] = useState(null);
//   const [city, setCity] = useState('');
//   const [state, setState] = useState('');

//   useEffect(() => {
//     const fetchLocation = async () => {
//       try {
//         const response = await fetch('https://ipapi.co/json/');
//         const data = await response.json();
//         setLocation(data);
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     fetchLocation();
//   }, []);

//   useEffect(() => {
//     const fetchAddress = async () => {
//       if (location) {
//         try {
//           const response = await fetch(
//             `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.latitude},${location.longitude}&key=YOUR_API_KEY`
//           );
//           const data = await response.json();
//           if (data.results.length > 0) {
//             const addressComponents = data.results[0].address_components;
//             const cityObj = addressComponents.find(
//               (component) => component.types.includes('locality')
//             );
//             setCity(cityObj.long_name);
//             const stateObj = addressComponents.find(
//               (component) => component.types.includes('administrative_area_level_1')
//             );
//             setState(stateObj.long_name);
//           }
//         } catch (error) {
//           console.error(error);
//         }
//       }
//     };
//     fetchAddress();
//   }, [location]);

//   return (
//     <div>
//       {location && (
//         <p>
//           You are in {location.city}, {location.region}
//         </p>
//       )}
//       {city && state && <p>Your address: {city}, {state}</p>}
//     </div>
//   );
// };

// export default WeatherApp;

// // import React, { useState, useEffect } from "react";

// // const WeatherApp = () => {
// //   const [latitude, setLatitude] = useState(null);
// //   const [longitude, setLongitude] = useState(null);
// //   const [error, setError] = useState(null);

// //   useEffect(() => {
// //     if (!navigator.geolocation) {
// //       setError("Geolocation is not supported by your browser");
// //     } else {
// //       navigator.geolocation.getCurrentPosition(
// //         (position) => {
// //           setLatitude(position.coords.latitude);
// //           setLongitude(position.coords.longitude);
// //         },
// //         () => {
// //           setError("Unable to retrieve your location");
// //         }
// //       );
// //     }
// //   }, []);

// //   if (error) {
// //     return <div>{error}</div>;
// //   }

// //   if (!latitude || !longitude) {
// //     return <div>Loading...</div>;
// //   }

// //   return (
// //     <div>
// //         <h1>Location</h1>
// //       Your location: ({latitude}, {longitude})
// //     </div>
// //   );
// // };

// // export default WeatherApp;
