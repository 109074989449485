import React from 'react';
import './Contact.css';
import Navbar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import FormContact from '../../components/form/FormContact';
import Fade from 'react-reveal/Fade';

const Contact = () => {
	return (
		<div>
			<Navbar />
			<Fade duration={1000}>
			<FormContact />
			<Footer />
			</Fade>
		</div>
	);
};

export default Contact;
