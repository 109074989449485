import React from 'react';
import './PortfolioProjects.css'
import '../footer/Footer';
import '../powerShell/PowerShell';
import '../coding/Coding';
import Powershell from '../powerShell/PowerShell';
import Coding from '../coding/Coding';
import RubberBand from 'react-reveal/RubberBand';
import Jello from 'react-reveal/Jello';

const PortfolioProjects = () => {
  return (
    <div className="portfolio-container">
      <div className="left-column">
      <RubberBand>
        <h2>PowerShell Scripts</h2>
        </RubberBand>
        <p>Here are PowerShell Scripts I wrote to assist with repetive tasks.</p>
        <Powershell />
      </div>
      <div className="right-column">
        <Jello>
        <h2>JavaScript & React Projects</h2>
        </Jello>
        <Coding />
      </div>
    </div>
  );
};

export default PortfolioProjects;
