import React from 'react';
import githubIcon from '../../assets/icons/github.svg';
import linkedinIcon from '../../assets/icons/linkedin-in.svg';
import './Footer.css';

const Footer = () => {
	return (
		<footer className='footer'>
			<div className='footer-left'>
				{/* <h3>Company Name</h3> */}
				{/* <p>&copy; 2023 Company Name</p> */}
			</div>
			<div className='footer-right'>
				<h3 className='footer-text'>Connect with me</h3>
				<ul className='social-media'>
					<li>
						<a
							title='Paul DelPadre GitHub'
							href='https://github.com/whiterice2020'
							target='_blank'
              rel='noopener noreferrer'>
							<img
								src={githubIcon}
								alt="Paul DelPadre's Github"
								title='Paul DelPadre GitHub'
							/>
						</a>
					</li>
					<li>
						<a
							title="Paul DelPadre's LinkedIn"
							href='https://www.linkedin.com/in/pauldelpadre/'
							target='_blank'
              rel='noopener noreferrer'>
							<img
								src={linkedinIcon}
								alt="Paul DelPadre's LinkedIn"
								title="Paul DelPadre's LinkedIn"
							/>
						</a>
					</li>
				</ul>
					<p className='footer-p'>Website built in React by Paul DelPadre</p>
			</div>
		</footer>
	);
};

export default Footer;
