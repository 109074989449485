const imageFolderPath = process.env.PUBLIC_URL + '/images';
const scriptsPath = process.env.PUBLIC_URL + './psScripts';

const imageNames = ['image1.jpg', 'image2.jpg', 'image3.jpg', 'image4.jpg', 
'image6.jpg', 'image7.jpg', 'image8.jpg', 'image9.jpg',
'image10.jpg',
'image11.jpg',
'image12.jpg',
'image13.jpg',
'image14.jpg',
'image15.jpg',
'image16.jpg',
'image17.jpg',


];

const PowerShellCardData = [
    {
      image: `${imageFolderPath}/${imageNames[Math.floor(Math.random() * imageNames.length)]}`,
      title: "Copy Files to Remote PCs",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit ex sed turpis bibendum, et vestibulum magna vestibulum.",
      link: "https://drive.google.com/file/d/1GIHcpJdrbyoTn9opT9-Ge2X1fzHinj7o/view?usp=share_link",
      buttonText: "Download"
    },
    {
      image: `${imageFolderPath}/${imageNames[Math.floor(Math.random() * imageNames.length)]}`,
      title: "Install Files on Remote PCs",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit ex sed turpis bibendum, et vestibulum magna vestibulum.",
      link: "https://drive.google.com/file/d/1H2S_WrnHpoXcgI0F_BGrIZBwofphSu0H/view?usp=share_link",
      buttonText: "Download"
    },
    {
      image: `${imageFolderPath}/${imageNames[Math.floor(Math.random() * imageNames.length)]}`,
      title: "Send Template Emails",
      text: "This script sends out template emails.  For Repetive email tasks.",
      link: "https://drive.google.com/file/d/1IpzAg-LXOeVYUwc1pIQ__6aaznRDDn5W/view?usp=share_link",
      buttonText: "Download"
    },
    {
      image: `${imageFolderPath}/${imageNames[Math.floor(Math.random() * imageNames.length)]}`,
      title: "Rename All Files in a Directory Incremental",
      text: "Ever have to name multiple files in a folder?  Use this script to rename them all with 1 click!",
      link: "https://drive.google.com/file/d/1xbBM1TxevUFQror3gN-7nbnxczn3xo2f/view?usp=share_link",
      buttonText: "Download"
    },
    {
      image: `${imageFolderPath}/${imageNames[Math.floor(Math.random() * imageNames.length)]}`,
      title: "Grab all AD Objects in an OU and export results to a file",
      text: "Great Tool if you have to gather pc or user names to build a report or a script",
      link: "https://drive.google.com/file/d/16NdzEKilVG3-vtutfclvt5mREpQOlmRy/view?usp=share_link",
      buttonText: "Download"
    },
    {
      image: `${imageFolderPath}/${imageNames[Math.floor(Math.random() * imageNames.length)]}`,
      title: "Open Last Email Sent in Outlook",
      text: "If you work a ticket queue in IT, then you know documenting all emails related to the issue is a must.  Use this to quickly open the last sent email in Outlook so you can copy the email into your incident ticket",
      link: "https://drive.google.com/file/d/1Y5yVMuUMLkYixsmT6cECQ_7xT35TdpjY/view?usp=share_link",
      buttonText: "Download"
    },
];

export default PowerShellCardData;