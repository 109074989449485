import React from 'react';
import './Portfolio.css';
import Navbar from '../../components/navbar/NavBar';
import Footer from '../../components/footer/Footer';
import PortfolioProjects from '../../components/portfolioProjects/PortfolioProjects';
import Fade from 'react-reveal/Fade';

const Portfolio = () => {
	return (
		<div>
			<Navbar />
			<Fade duration={1000}>
            <PortfolioProjects />
			<Footer />
			</Fade>
		</div>
	);
};

export default Portfolio;
